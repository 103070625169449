.button,
input[type='submit'] {
  --btn-bg: var(--btn-primary-color);
  --btn-color: #fff; /* Assume light on dark for most buttons */
  --btn-border: var(--btn-bg);
  --btn-radius: var(--elem-radius, 4px);

  appearance: none;

  display: inline-block;
  max-width: var(--btn-max-width);
  padding: 0;
  border: none;
  background: none;
  text-decoration: none;
  cursor: pointer;
}

.button .icon {
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.5ch;
}

.button.wide {
  max-width: none;
}

.button[disabled] {
  cursor: default;
  opacity: 0.2;
  pointer-events: none;
}

.button > span,
input[type='submit'] {
  min-width: 8ch;
  height: var(--btn-height);
  padding-right: 0.6rem;
  padding-left: 0.6rem;

  border: 2px solid var(--btn-border);
  border-radius: var(--btn-radius);
  background: var(--btn-bg);
  font-weight: 500;
  color: var(--btn-color);
}

.button > span {
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.button:hover > span {
  --btn-bg: var(--color2);
  --btn-border: var(--btn-bg);
  --btn-color: #fff;
  transition: var(--anim);
}

/* Handle light colors */
.button:is(.color4, .color6, .color9, .color10, .color12, .color13, .color16):not(.secondary) {
  --btn-color: var(--color1);
}

.button.secondary {
  --btn-primary-color: var(--btn-secondary-color);

  --btn-bg: transparent;
  --btn-border: var(--btn-primary-color);
  --btn-color: var(--btn-primary-color);
}

.button.secondary:hover > span {
  --btn-bg: var(--btn-primary-color);
  --btn-border: var(--btn-bg);
  --btn-color: var(--font-dark);
}

.button.secondary[class*='color']:hover > span {
  --btn-color: #fff;
}

.button.secondary.color9:hover > span {
  --btn-color: var(--color1);
}

.button.color1 {
  --btn-primary-color: var(--color1);
}
.button.color2 {
  --btn-primary-color: var(--color2);
}
.button.color3 {
  --btn-primary-color: var(--color3);
}
.button.color4 {
  --btn-primary-color: var(--color4);
}
.button.color5 {
  --btn-primary-color: var(--color5);
}
.button.color6 {
  --btn-primary-color: var(--color6);
}
.button.color7 {
  --btn-primary-color: var(--color7);
}
.button.color8 {
  --btn-primary-color: var(--color8);
}
.button.color9 {
  --btn-primary-color: var(--color9);
}
.button.color10 {
  ---btn-primary-color: var(--color10);
}
.button.color11 {
  --btn-primary-color: var(--color11);
}
.button.color12 {
  --btn-primary-color: var(--color12);
}
.button.color13 {
  --btn-primary-color: var(--color13);
}
.button.color16 {
  --btn-primary-color: var(--color16);
}
.button.colorWhite {
  --btn-primary-color: #fff;
}

.button.small > span {
  height: calc(var(--btn-height) * 4 / 5);
  font-size: 0.8rem;
}

.button.medium > span {
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  height: calc(var(--btn-height) * 1.2);
  font-size: 1.2rem;
}

.button.back-cta {
  --btn-bg: transparent;
  --btn-color: var(--color15);
  margin-left: -0.5ch;
  font-size: 0.9rem;
}

.button.back-cta > span {
  padding-left: 0.5ch;
}

.linkButton {
  --btn-primary-color: var(--font-dark);
  --btn-bg: transparent;
  --btn-color: var(--btn-primary-color);
  border: none;
}

.linkButton > span {
  text-decoration: underline;
}

.linkButton:hover > span {
  text-decoration: none;
}

.cancelButton {
  --btn-bg: var(--color0);
  --btn-color: #fff;
}

.cancelButton > span {
  min-width: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 2px solid var(--color2);
  place-content: center;
}

.cancelButton .icon {
  --size: 1.8rem;
  margin-right: 0;
}

.button-group {
  display: flex;
  gap: var(--spacer-half);
}
