.block-center {
  margin-right: auto;
  margin-left: auto;
}

.center {
  justify-content: center;
  text-align: center;
}

.text-center {
  text-align: center;
}

.small {
  font-size: 0.8rem;
}

.flex {
  display: flex;
  gap: var(--gap);
}

.ml-auto {
  margin-left: auto;
}

.ml-right {
  margin-right: auto;
}

.has-larrow,
.has-rarrow {
  display: inline-block;
}

.has-larrow:before,
.has-rarrow:after {
  position: relative;
  display: inline-block;
  height: 0.75rem;
  width: 1rem;
  margin-left: 0.5ch;
  vertical-align: middle;
  background-image: url(../../images/caret.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
}

.has-larrow:before {
  margin-right: 0.5ch;
  margin-left: 0;
  transform: rotate(180deg);
}

[disabled] {
  opacity: 0.6;
}

.cover:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  display: block;

  width: 100%;
  height: 100%;

  content: '';
}

.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

@media (max-width: 768px) {
  .sr-only-small {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
}

@media (min-width: 768px) {
  .sr-only-large {
    border: 0 !important;
    clip: rect(1px, 1px, 1px, 1px) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
    white-space: nowrap !important;
  }
}

.hide,
.hidden {
  display: none;
}
