.copy-code {
  color: #fff;
}

.countHighlight {
  position: relative;
  top: -2px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  margin: 0 0.35ch;

  width: 1.3rem;
  height: 1.2rem;

  border-radius: var(--elem-radius);
  background-color: var(--color10);

  font-size: 0.9rem;
  line-height: 1;
  color: var(--color0);
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background: var(--scrollbar-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--scrollbar-color);
}
