.app-control {
  display: flex;
  flex-direction: column;
  padding: var(--spacer) var(--gutter) 0;
}

.app-control > .tabs {
  padding: var(--gutter);
  padding-bottom: 0;
}

.app-control > .col-wrapper {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - var(--header-height) - var(--spacer));
}

.selectedHeader {
  display: flex;
  gap: var(--gap);
  margin-bottom: var(--spacer-half);
  padding-right: 10px;
}

.selectedHeader-headline {
  margin-bottom: 0;
  margin-right: auto;
}

.selectedHeader .icon {
  order: 2;
  margin-left: 1.5ch;
  width: 1.4rem;
  height: 1.4rem;
  cursor: help;
}

.selectedList {
  flex: 0 0 300px;

  padding-bottom: var(--spacer-half);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.selectedList:empty {
  display: none;
}

.selectedList .schema-card,
.selectedList .schema-card .addSchema-icon {
  background-color: var(--card-color-alt);
}

.templateList {
  flex: 1 0 160px;
}

.selectedList,
.templateList {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--spacer-half);
  overflow: hidden;
}

.selectedList .scrollable,
.templateList .scrollable {
  flex: 1;
  min-height: 0;
  padding-bottom: 0;
}

.selectedList .object-list,
.templateList .object-list {
  margin-bottom: 0;
}
