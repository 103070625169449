.app-header {
  position: fixed;
  z-index: 10;
  display: flex;
  gap: 0.75rem;
  width: 100%;
  height: var(--header-height);
  padding-right: var(--gutter);
  background-color: #f9fdfd;
  background-image: url(../../images/bg-effect.svg);
  background-position: top right;
  background-repeat: no-repeat;
}

.app-header .logo {
  display: flex;
  align-items: center;
  gap: var(--spacer-half);
  text-decoration: none;
}

.app-header .brand {
  display: flex;
  margin-right: auto;
  padding-left: var(--gutter);
}

.brand-appName {
  align-self: center;
  margin-left: calc(var(--spacer-half) - 2px);
  padding-left: calc(var(--spacer-half) - 2px);
  border-left: 2px solid #d4c2e4;
  font-weight: var(--weight-bold);
  text-decoration: none;
  color: var(--color0);
}

.app-header .logo.loading {
  animation-direction: alternate;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-name: bounce;
  animation-timing-function: cubic-bezier(0.95, 0.05, 0.8, 0.05);
  height: 100px;
}

.app-header-actions {
  display: flex;
  gap: 0.5rem;
}

.endpointForm .inline-label {
  padding-right: 4px;
}

.app-header .endpointForm {
  --input-height: 30px;
  display: flex;
  align-items: center;
}

.app-header .endpointForm .inline-label {
  border: 1px solid var(--color4);
}

.app-header .endpointForm label {
  border-right-color: rgba(0, 178, 202, 0.3);
  background-color: rgba(0, 178, 202, 0.1);
  color: var(--color2);
}

.app-header .endpointForm input {
  font-size: 0.85rem;
  color: var(--font-dark);
}

.app-header .endpointForm .copy-code {
  color: var(--font-dark);
}

.app-header .button {
  --btn-height: 34px;
  align-self: center;
}

@media (min-width: 960px) {
  .app-header .endpointForm input {
    width: 16ch;
  }
}
