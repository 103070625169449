::marker {
  display: none;
}

details,
summary {
  appearance: none;
  -webkit-appearance: none;
  list-style: none;
}

details summary::-webkit-details-marker {
  display: none;
}

summary:focus {
  outline: none;
}

.app-overlay.tutorial-overlay {
  padding: 0;
}

.app-overlay .tutorial-overlay-wrapper {
  padding: var(--spacer-half) 0 40px;
  overflow-y: auto;
}

.tutorial {
  --spacing: var(--spacer-half);
  --main-col-width: 540px;
  --icon-size: 22px;

  position: relative;
  width: 100%;
  height: calc(100% - 40px);
  padding: 0 var(--spacer-half) 40px;
  overflow-y: auto;
}

.tutorialSection {
  margin: 0 auto;
  width: 100%;
  padding-bottom: 40px;
}

.tutorialSection-header-content {
  margin-bottom: var(--spacer);
}

.tutorialSection-header-media {
  display: none;
}

.tutorialSection-highlights {
  margin-bottom: var(--spacer);
}

.tutorialHighlight {
  position: relative;
  display: block;
  padding: var(--spacing);
  border-radius: var(--elem-radius);
  border: var(--card-border);
  text-decoration: none;
  color: var(--font-color);

  transition: var(--anim);
}

.tutorialHighlight h2 {
  margin-bottom: 0;
  font-size: 1rem;
}

.tutorialHighlight + .tutorialHighlight {
  margin-top: calc(var(--spacer) * 2 / 3);
}

.tutorialHighlight > *:last-child {
  margin-bottom: 0;
}

.tutorialHighlight[open] {
  border: var(--card-border);
  background-color: var(--card-color-alt);
}

.tutorialHighlight summary {
  position: relative;
  cursor: pointer;
}

.tutorialHighlight summary * {
  margin-bottom: 0;
}

.tutorialSection-highlightContent {
  margin-top: calc(var(--spacer) * 1 / 4);
}

.tutorialHighlight summary,
.tutorialSection-highlightContent {
  padding-left: calc(var(--icon-size) + 1.25ch);
}

.tutorialHighlight summary:before {
  --icon: url(../../images/icons/circle.svg);

  position: absolute;
  top: -2px;
  left: 0;
  z-index: 10;
  display: inline-block;
  width: var(--icon-size);
  height: var(--icon-size);

  background-image: ;

  mask: var(--icon) no-repeat center;
  -webkit-mask: var(--icon) no-repeat center;

  mask-size: cover;
  -webkit-mask-size: cover;

  background-color: var(--color4);

  content: '';
}

.tutorialHighlight[data-completed='true'] summary:before {
  --icon: url(../../images/icons/check.svg);
  background-color: var(--color9);
}

.tutorial-sectionNav {
  display: flex;
  justify-content: space-between;
}

.tutorial-navWrap {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 8px var(--gutter) 12px;
  background-color: var(--color2);
}

.legal-disclaimer {
  display: block;
  margin-top: calc(var(--spacer) * 1.5);
  font-size: 0.7rem;
  text-align: center;
  opacity: 0.6;
}

.dotNav {
  display: flex;
  align-items: center;
  gap: 1ch;
  margin: 0;
  padding: 0;
}

.dotNav li {
  display: flex;
  align-items: center;
  list-style: none;
}

/* !!! small click targets */
.dotNav button {
  appearance: none;
  border: none;
  background-color: transparent;
  display: inline-block;
  cursor: pointer;
}

.dotNav button > span {
  --size: 12px;

  display: block;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  border: 2px solid #fff;
  text-indent: -999rem;
  opacity: 0.3;
}

.dotNav [aria-current='true'] button > span {
  --size: 16px;
  background-color: #fff;
}

@media (min-width: 960px) {
  .app-overlay.tutorial-overlay {
    padding: calc(var(--spacer) * 1.5);
  }

  .app-overlay .tutorial-overlay-wrapper {
    flex: initial;
    position: relative;
    padding: var(--spacer) 8px;
    height: auto;
    width: 100%;
  }

  .tutorial {
    height: 100%;
    padidng-bottom: 0;
  }

  .tutorialSection {
    padding-bottom: 0;
  }

  .tutorialSection-header {
    display: flex;
    gap: var(--spacer-half);
    max-width: calc(var(--main-col-width) + 100px);
  }

  .tutorialSection-header-media {
    display: block;
    flex: 0 0 70px; /* !! Eyeballed */
    text-align: center;
  }

  .tutorialSection-contents {
    display: flex;
    gap: var(--gap);
  }

  .tutorialSection-highlights {
    flex: 1;
    padding-bottom: var(--btn-height);
  }

  .tutorialSection-media {
    flex: 1;
    display: flex;
    flex-flow: column;
    margin-top: -60px;
  }

  .tutorialSection-media.type-video {
    display: flex;
    justify-content: center;
    align-items: center;

    padding: var(--spacer-2) 0 0;

    background-image: url(../../images/circles.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  .tutorialSection .video-embed {
    width: 100%;
    height: 100%;
  }

  .tutorialSection video {
    box-shadow: -4px 4px 4px rgba(25, 37, 63, 0.4);
    border-radius: var(--elem-radius);
  }

  .tutorial-navWrap {
    position: absolute;
    margin: 0 auto;
    padding: 0 40px 0 var(--spacer);
    width: 50%;
  }
}
