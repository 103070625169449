.tabs {
  margin-bottom: var(--spacer-half);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.tabs,
.tabs > ul {
  display: flex;
  gap: var(--spacer-half);
}

.tabs > ul {
  margin: 0;
  padding: 0;
}

.tabs li {
  list-style: none;
}

.tabs .tab {
  --btn-bg: transparent;
  --btn-color: var(--color13);
  --btn-border: var(--btn-bg);
  --btn-radius: 0;

  border: none;
  border-bottom: 3px solid transparent;

  font-size: 1rem;
}

.tabs .tab:hover > span {
  --btn-bg: transparent;
}

.tab > span {
  min-width: 6ch;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
}

.tabs .icon {
  margin-right: 0.75ch;
  color: var(--color4);
}

.tabs .tab[aria-selected='true'] {
  border: none;
  border-bottom: 3px solid #fff;
  background-color: transparent;
  color: #fff;
}

.tabs .tab[aria-selected='true'] .icon {
  color: #fff;
}
