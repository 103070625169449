@import 'graphiql/graphiql.min.css';

.graphiql-wrapper {
  /* Offest from bottom */
  --bot-padding: 10px;

  /* 
    Needs to align with contents of topBarWrap 
    Rough height of tablist, button, spacing, etc
  */
  --offset: calc(var(--spacer-2) + 1.22rem);

  position: relative;

  /* 
    Estimated top padding
    Roughly based on half button height, line-height, and more, but not exact
  */
  padding: 21px var(--gutter) var(--bot-padding);

  background: linear-gradient(90deg, var(--color1) 50%, transparent 50%);

  .loading-overlay {
    background: var(--color1);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.schema-showing {
    .queryWrap,
    .variable-editor,
    .codemirrorWrap {
      display: none;
    }
  }

  .graphiql-schema-view {
    position: absolute;
    top: var(--offset);
    left: 0;

    display: flex;
    flex-direction: column;

    padding: 0 var(--gutter);
    width: 50%;
    height: calc(100% - var(--offset) - var(--bot-padding));

    .scrollable {
      flex: 1;
      overflow: auto;
    }

    .scrollable > pre {
      margin-top: 0;
    }

    &.hidden {
      display: none;
    }
  }
}

.graphiql-toolbar {
  display: flex;
  gap: calc(var(--gutter) * 2);
  width: 100%;

  .column {
    flex: 1;
  }

  .run-container {
    flex: 2;
    position: relative;
    margin-left: auto;
    text-align: right;
  }
}

.graphiql-container {
  position: relative;

  .topBar {
    background: transparent;
    border: 0;
    overflow: visible;
    padding: 0;
    position: relative;
    width: 100%;
    z-index: 100;

    height: auto;

    .title {
      display: none;
    }

    .execute-button-wrap {
      display: none;
    }
  }

  .toolbar {
    width: 100%;
  }

  .docExplorerShow {
    display: none;
  }

  .resultWrap,
  .queryWrap {
    flex: 0 1 calc(50% - var(--gutter));
  }

  .resultWrap {
    margin-left: auto;
    border-left: 0;

    .CodeMirror-gutters {
      background: transparent;
    }
  }

  .queryWrap {
    .CodeMirror-gutters {
      background-color: transparent;
    }
  }

  .search-box-icon {
    color: #fff;
  }

  .docExplorerWrap {
    background: var(--bg-color);
    bottom: 5px;
    box-shadow: none;
    position: absolute;
    right: 0;
    top: 43px;
    width: calc(50% - 2px) !important;
    z-index: 100;

    .docExplorerResizer {
      pointer-events: none;
    }

    .doc-explorer {
      background: var(--bg-color);
      font-family: var(--font-body);
      font-size: 16px;
      font-weight: var(--weight-normal);
    }

    .doc-explorer-title-bar {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      display: flex;
      height: 60px;
      justify-content: flex-start;
      line-height: 1;
      margin: 0 0 0 15px;
      padding: 15px 0 0;

      .docExplorerHide {
        display: none;
      }

      .doc-explorer-back {
        &:focus,
        &:hover {
          background: rgba(255, 255, 255, 0.3);
        }
      }

      .doc-explorer-title,
      .doc-explorer-back {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        color: #fff;
        display: block;
        flex: 0;
        font: inherit;
        font-size: 14px;
        height: 30px;
        line-height: 30px;
        margin: 0;
        overflow: visible;
        padding: 0 10px;

        &:before {
          border-color: currentColor;
          color: inherit;
        }
      }

      :nth-child(2) {
        display: none;
      }
    }
  }

  .search-box {
    border-bottom-color: rgba(255, 255, 255, 0.1);

    > input {
      font: inherit;

      &::placeholder {
        color: #89898a;
      }
    }

    .search-box-clear {
      background: none;
    }
  }

  .doc-explorer-contents {
    background: transparent;
    border-top-color: transparent;
    color: #ccc;
    top: 61px;

    a {
      color: #fff;
    }

    .arg-default-value {
      color: #ccc;
    }

    .arg-name {
      color: #cc99cd;
    }

    code {
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
    }

    .doc-category-item {
      color: #ccc;
    }

    .doc-category-title {
      border-bottom-color: rgba(255, 255, 255, 0.1);
      color: inherit;
    }

    .doc-type-description {
      a {
        text-decoration: underline;

        &:focus,
        &:hover {
          text-decoration: none;
        }
      }
    }

    .enum-value {
      color: #00b1c9;
    }

    .field-name {
      color: #f08d49;
    }

    .field-short-description {
      color: #ccc;
      margin: 10px 0 0;

      * {
        color: #ccc;
      }
    }

    .keyword {
      color: #ccc;
    }

    .type-name {
      color: #7ec699;
    }
  }

  .variable-editor {
    display: none;
  }
}

.graphiql-container button {
  font-family: var(--font-body);
  font-size: initial;
  color: #fff; /* !!! */
}

.CodeMirror pre {
  font-size: 14px;
}

.graphiql-container {
  .editorBar {
    gap: calc(var(--gutter) * 2);
  }

  .CodeMirror,
  .CodeMirror.cm-s-graphiql {
    background: transparent;
    color: #ccc;
    font-family: var(--font-code);
    font-size: 16px;
    line-height: 1.5;
  }

  /* PADDING */

  .CodeMirror-lines {
    padding: 0; /* Vertical padding around content */
  }

  // .CodeMirror pre.CodeMirror-line,
  // .CodeMirror pre.CodeMirror-line-like {
  //   padding: 0 4px; /* Horizontal padding of content */
  // }

  .CodeMirror-scrollbar-filler,
  .CodeMirror-gutter-filler {
    background-color: transparent;
  }

  // /* GUTTER */

  .CodeMirror-gutters {
    border-right: 0;
    cursor: default;
    pointer-events: none;
    width: 20px;
  }

  .CodeMirror-linenumber {
    display: none;
  }

  // .CodeMirror-guttermarker {
  //   color: black;
  // }
  // .CodeMirror-guttermarker-subtle {
  //   color: #999;
  // }

  // /* CURSOR */

  .CodeMirror-cursor {
    border-left: 1px solid #ccc;
    border-right: none;
    width: 0;
  }

  .CodeMirror-jump-token {
    cursor: default;
    pointer-events: none;
    text-decoration: none;
  }
  // /* Shown when moving in bi-directional text */
  // .CodeMirror div.CodeMirror-secondarycursor {
  //   border-left: 1px solid silver;
  // }
  // .cm-fat-cursor .CodeMirror-cursor {
  //   width: auto;
  //   border: 0 !important;
  //   background: #7e7;
  // }
  // .cm-fat-cursor div.CodeMirror-cursors {
  //   z-index: 1;
  // }
  // .cm-fat-cursor-mark {
  //   background-color: rgba(20, 255, 20, 0.5);
  //   -webkit-animation: blink 1.06s steps(1) infinite;
  //   -moz-animation: blink 1.06s steps(1) infinite;
  //   animation: blink 1.06s steps(1) infinite;
  // }
  // .cm-animate-fat-cursor {
  //   width: auto;
  //   -webkit-animation: blink 1.06s steps(1) infinite;
  //   -moz-animation: blink 1.06s steps(1) infinite;
  //   animation: blink 1.06s steps(1) infinite;
  //   background-color: #7e7;
  // }
  // @-moz-keyframes blink {
  //   0% {
  //   }
  //   50% {
  //     background-color: transparent;
  //   }
  //   100% {
  //   }
  // }
  // @-webkit-keyframes blink {
  //   0% {
  //   }
  //   50% {
  //     background-color: transparent;
  //   }
  //   100% {
  //   }
  // }
  // @keyframes blink {
  //   0% {
  //   }
  //   50% {
  //     background-color: transparent;
  //   }
  //   100% {
  //   }
  // }

  // /* Can style cursor different in overwrite (non-insert) mode */
  // .CodeMirror-overwrite .CodeMirror-cursor {
  // }

  // .cm-tab {
  //   display: inline-block;
  //   text-decoration: inherit;
  // }

  // .CodeMirror-rulers {
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: -50px;
  //   bottom: 0;
  //   overflow: hidden;
  // }
  // .CodeMirror-ruler {
  //   border-left: 1px solid #ccc;
  //   top: 0;
  //   bottom: 0;
  //   position: absolute;
  // }

  // /* DEFAULT THEME */

  .cm-header {
    color: blue;
  }
  .cm-quote {
    color: #090;
  }
  .cm-negative {
    color: #d44;
  }
  .cm-positive {
    color: #292;
  }
  .cm-header,
  .cm-strong {
    font-weight: bold;
  }
  .cm-em {
    font-style: italic;
  }
  .cm-link {
    text-decoration: underline;
  }
  .cm-strikethrough {
    text-decoration: line-through;
  }

  .cm-keyword {
    color: #cc99cd;
  }

  .cm-atom {
    color: #f8c555;
  }

  .cm-number {
    color: #f08d49;
  }

  .cm-def {
    color: #f08d49;
  }

  .cm-variable {
    color: #7ec699;
  }

  .cm-punctuation {
    color: #ccc;
  }

  .cm-property {
    color: #ccc;
  }

  .cm-qualifier {
    color: #ccc;
  }

  .cm-operator {
    color: #ccc;
  }

  .cm-variable-2 {
    color: #05a;
  }
  .cm-variable-3,
  .cm-type {
    color: #085;
  }
  .cm-comment {
    color: #777;
  }
  .cm-string {
    color: #7ec699;
  }
  .cm-string-2 {
    color: #f50;
  }
  .cm-meta {
    color: #555;
  }
  .cm-builtin {
    color: #81bee7;
  }
  .cm-bracket {
    color: #997;
  }
  .cm-tag {
    color: #170;
  }
  .cm-attribute {
    color: #e2777a;
  }
  .cm-hr {
    color: #999;
  }
  .cm-link {
    color: #e2777a;
  }

  .cm-error {
    color: #f00;
  }
  .cm-invalidchar {
    color: #f00;
  }

  // .CodeMirror-composing {
  //   border-bottom: 2px solid;
  // }

  // /* Default styles for common addons */

  // div.CodeMirror span.CodeMirror-matchingbracket {
  //   color: #0b0;
  // }
  // div.CodeMirror span.CodeMirror-nonmatchingbracket {
  //   color: #a22;
  // }
  // .CodeMirror-matchingtag {
  //   background: rgba(255, 150, 0, 0.3);
  // }
  // .CodeMirror-activeline-background {
  //   background: #e8f2ff;
  // }

  // /* STOP */

  // /* The rest of this file contains styles related to the mechanics of
  //  the editor. You probably shouldn't touch them. */

  // .CodeMirror {
  //   position: relative;
  //   overflow: hidden;
  //   background: white;
  // }

  // .CodeMirror-scroll {
  //   overflow: scroll !important; /* Things will break if this is overridden */
  //   /* 50px is the magic margin used to hide the element's real scrollbars */
  //   /* See overflow: hidden in .CodeMirror */
  //   margin-bottom: -50px;
  //   margin-right: -50px;
  //   padding-bottom: 50px;
  //   height: 100%;
  //   outline: none; /* Prevent dragging from highlighting the element */
  //   position: relative;
  // }
  // .CodeMirror-sizer {
  //   position: relative;
  //   border-right: 50px solid transparent;
  // }

  // /* The fake, visible scrollbars. Used to force redraw during scrolling
  //  before actual scrolling happens, thus preventing shaking and
  //  flickering artifacts. */
  // .CodeMirror-vscrollbar,
  // .CodeMirror-hscrollbar,
  // .CodeMirror-scrollbar-filler,
  // .CodeMirror-gutter-filler {
  //   position: absolute;
  //   z-index: 6;
  //   display: none;
  //   outline: none;
  // }
  // .CodeMirror-vscrollbar {
  //   right: 0;
  //   top: 0;
  //   overflow-x: hidden;
  //   overflow-y: scroll;
  // }
  // .CodeMirror-hscrollbar {
  //   bottom: 0;
  //   left: 0;
  //   overflow-y: hidden;
  //   overflow-x: scroll;
  // }
  // .CodeMirror-scrollbar-filler {
  //   right: 0;
  //   bottom: 0;
  // }
  // .CodeMirror-gutter-filler {
  //   left: 0;
  //   bottom: 0;
  // }

  // .CodeMirror-gutters {
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   min-height: 100%;
  //   z-index: 3;
  // }
  // .CodeMirror-gutter {
  //   white-space: normal;
  //   height: 100%;
  //   display: inline-block;
  //   vertical-align: top;
  //   margin-bottom: -50px;
  // }
  // .CodeMirror-gutter-wrapper {
  //   position: absolute;
  //   z-index: 4;
  //   background: none !important;
  //   border: none !important;
  // }
  // .CodeMirror-gutter-background {
  //   position: absolute;
  //   top: 0;
  //   bottom: 0;
  //   z-index: 4;
  // }
  // .CodeMirror-gutter-elt {
  //   position: absolute;
  //   cursor: default;
  //   z-index: 4;
  // }
  // .CodeMirror-gutter-wrapper ::selection {
  //   background-color: transparent;
  // }
  // .CodeMirror-gutter-wrapper ::-moz-selection {
  //   background-color: transparent;
  // }

  // .CodeMirror-lines {
  //   cursor: text;
  //   min-height: 1px; /* prevents collapsing before first draw */
  // }
  // .CodeMirror pre.CodeMirror-line,
  // .CodeMirror pre.CodeMirror-line-like {
  //   /* Reset some styles that the rest of the page might have set */
  //   -moz-border-radius: 0;
  //   -webkit-border-radius: 0;
  //   border-radius: 0;
  //   border-width: 0;
  //   background: transparent;
  //   font-family: inherit;
  //   font-size: inherit;
  //   margin: 0;
  //   white-space: pre;
  //   word-wrap: normal;
  //   line-height: inherit;
  //   color: inherit;
  //   z-index: 2;
  //   position: relative;
  //   overflow: visible;
  //   -webkit-tap-highlight-color: transparent;
  //   -webkit-font-variant-ligatures: contextual;
  //   font-variant-ligatures: contextual;
  // }
  // .CodeMirror-wrap pre.CodeMirror-line,
  // .CodeMirror-wrap pre.CodeMirror-line-like {
  //   word-wrap: break-word;
  //   white-space: pre-wrap;
  //   word-break: normal;
  // }

  // .CodeMirror-linebackground {
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   z-index: 0;
  // }

  // .CodeMirror-linewidget {
  //   position: relative;
  //   z-index: 2;
  //   padding: 0.1px; /* Force widget margins to stay inside of the container */
  // }

  // .CodeMirror-widget {
  // }

  // .CodeMirror-rtl pre {
  //   direction: rtl;
  // }

  // .CodeMirror-code {
  //   outline: none;
  // }

  // /* Force content-box sizing for the elements where we expect it */
  // .CodeMirror-scroll,
  // .CodeMirror-sizer,
  // .CodeMirror-gutter,
  // .CodeMirror-gutters,
  // .CodeMirror-linenumber {
  //   -moz-box-sizing: content-box;
  //   box-sizing: content-box;
  // }

  // .CodeMirror-measure {
  //   position: absolute;
  //   width: 100%;
  //   height: 0;
  //   overflow: hidden;
  //   visibility: hidden;
  // }

  // .CodeMirror-cursor {
  //   position: absolute;
  //   pointer-events: none;
  // }
  // .CodeMirror-measure pre {
  //   position: static;
  // }

  // div.CodeMirror-cursors {
  //   visibility: hidden;
  //   position: relative;
  //   z-index: 3;
  // }
  // div.CodeMirror-dragcursors {
  //   visibility: visible;
  // }

  // .CodeMirror-focused div.CodeMirror-cursors {
  //   visibility: visible;
  // }

  .CodeMirror-selected {
    background: var(--bg-color);
  }

  .resultWrap .CodeMirror-selected {
    background: var(--color1);
  }
  // .CodeMirror-focused .CodeMirror-selected {
  //   background: #d7d4f0;
  // }
  // .CodeMirror-crosshair {
  //   cursor: crosshair;
  // }
  // .CodeMirror-line::selection,
  // .CodeMirror-line > span::selection,
  // .CodeMirror-line > span > span::selection {
  //   background: #d7d4f0;
  // }
  // .CodeMirror-line::-moz-selection,
  // .CodeMirror-line > span::-moz-selection,
  // .CodeMirror-line > span > span::-moz-selection {
  //   background: #d7d4f0;
  // }

  // .cm-searching {
  //   background-color: #ffa;
  //   background-color: rgba(255, 255, 0, 0.4);
  // }

  // /* Used to force a border model for a node */
  // .cm-force-border {
  //   padding-right: 0.1px;
  // }

  // @media print {
  //   /* Hide the cursor when printing */
  //   .CodeMirror div.CodeMirror-cursors {
  //     visibility: hidden;
  //   }
  // }

  // /* See issue #2901 */
  // .cm-tab-wrap-hack:after {
  //   content: "";
  // }

  // /* Help users use markselection to safely style text background */
  // span.CodeMirror-selectedtext {
  //   background: none;
  // }
}

.CodeMirror-info {
  display: none;
}
