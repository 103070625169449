.comboDetails {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.comboDetails-header {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  padding: var(--spacer) var(--spacer-half) var(--spacer-half);
}

.comboDetails-badge {
  order: 1;
}

.comboDetails .cancelButton {
  order: 2;
  margin-left: auto;
}

.comboDetails-title {
  margin-bottom: 0;
  font-size: 1.2rem;
}

.comboDetails-includes {
  margin-bottom: 0;
  font-size: 1.1rem;
  color: var(--font-color-alt);
}

.comboDetails-header-content {
  order: 3;
  width: 100%;
  margin-top: var(--spacer-half);
}

.comboDetails-author {
  display: flex;
  align-items: center;
  gap: 0.5ch;
  color: #fff;
}

.comboDetails-description {
  flex: 1;
  padding: var(--spacer) var(--spacer-half);
  background-color: var(--color1);
}

.comboDetails-description > :last-child {
  margin-bottom: 0;
}

.comboDetails-meta {
  display: flex;
  justify-content: space-between;
  padding: var(--spacer-half);
}

.comboDetails-meta .addSchema-icon,
.comboDetails-meta .combo-card-count {
  /* !!! */
  width: 1.9rem;
  height: 1.9rem;
}

.comboDetails-meta .combo-card-count {
  font-size: 1rem;
}

@media (min-width: 620px) {
  .comboDetails-overlay .col-wrapper {
    flex: 0 1 auto;
    max-width: calc(var(--max-width) * 1 / 2.5);
  }

  .comboDetails-description {
    --border-radius: calc(var(--elem-radius) * 3);
    border-radius: 0 0 var(--border-radius) var(--border-radius); /* !!! */
  }

  .comboDetails-header-content {
    width: auto;
    margin-top: 0;
    margin-left: var(--spacer-half);
    padding-left: var(--spacer-half);
    border-left: 1px solid #00b2ca20;
  }
}
