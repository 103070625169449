/*
  StepZen CSS File
*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400&family=Roboto+Mono&display=swap');

/*
  BEGIN BASE STYLES
*/

@import url('reset.css');

@import url('elements/typography.css');
@import url('elements/button.css');
@import url('elements/forms.css');
@import url('elements/links.css');
@import url('elements/icons.css');
@import url('elements/icon-button.css');
@import url('elements/lists.css');
@import url('elements/misc.css');
@import url('elements/video.css');

@import url('skeleton/layout.css');
@import url('skeleton/header.css');

@import url('skeleton/app-main.css');

@import url('skeleton/app-actions.css');
@import url('skeleton/app-control.css');
@import url('skeleton/app-nav.css');
@import url('skeleton/app-focal.css');
@import url('skeleton/app-overlay.css');
@import url('skeleton/app-results.css');

@import url('components/add-banner.css');
@import url('components/addSchema.css');
@import url('components/alert.css');
@import url('components/application-prompts.css');
@import url('components/card.css');
@import url('components/combo-card.css');
@import url('components/comboDetails.css');
@import url('components/dialog.css');
@import url('components/documentation.css');
@import url('components/add-source-form.css');
@import url('components/schema-card.css');
@import url('components/loading.css');
@import url('components/mini-tutorial.css');
@import url('components/modal.css');
@import url('components/publish-box.css');
@import url('components/schema-config.css');
@import url('components/signup-banner.css');
@import url('components/tabs.css');
@import url('components/tutorial.css');

@import url('utilities/helpers.css');
@import url('utilities/color.css');

@import url('code.css');

:root {
  /* Grid & Spacing */
  --max-width: 1280px;
  --spacer: 30px;
  --spacer-half: 15px;
  --spacer-2: 60px;
  --spacer-3: 90px;
  --spacer-4: 120px;
  --gutter: calc(var(--spacer) / 3);
  --gap: calc(var(--spacer) * 2);
  --gap-half: var(--spacer);

  /* Max width any object should spill into gutters */
  --extend-width: var(--gutter);

  /* Typography */
  --font-body: 'Roboto', sans-serif;
  --font-code: 'Roboto Mono', Menlo, Consolas, Monaco, Liberation Mono,
    Lucida Console, monospace;

  --weight-normal: 400;
  --weight-medium: 500;
  --weight-bold: 700;
  --weight-black: 900;

  /* Colors */
  --color0: #0f182d;

  --color1: #19253f; /* Space Cadet */
  --color2: #193460; /* Prussian Blue */
  --color3: #1d4e89; /* Yale Blue */
  --color4: #00b2ca; /* Pacific Blue */
  --color5: #00b1c9; /* Iris Blue */
  --color6: #b6e1e4; /* Powder Blue */
  --color7: #8b6091; /* French Lilac */
  --color8: #907f9f; /* Mountbatten Pink */
  --color9: #ed865a; /* Mandarin */
  --color10: #fdc88c; /* Deep Champagne */
  --color11: #2163ef; /* Simple Blue */
  --color12: #b2cff9; /* Pale Cornflower */
  --color13: #d4c2e4; /* Prelude */
  --color14: #e2fcfe; /* Light Cyan */
  --color15: #d3f4f6;
  --color16: #ecb22e; /* Gold */

  --bg-color: var(--color2);
  --font-color: #c8e9ef;
  --font-color-alt: #cfd1d5;
  --font-dark: var(--color0);

  /* Elements */
  --form-spacer: 20px;
  --input-height: 2.5rem;
  --input-border-color: #717a8f;
  --input-border: 1px solid var(--input-border-color);

  --btn-height: var(--input-height);
  --btn-primary-color: var(--color3);
  --btn-secondary-color: #fff;
  --btn-max-width: 24ch;

  --elem-radius: 4px;

  --box-shadow: 0px 32px 70px 9px var(--color0);

  /* Components */
  --card-color: var(--color3);
  --card-color-alt: #00426e;
  --card-border: 1px solid var(--card-color);

  --header-height: 48px;

  --scrollbar-color: #040d21;

  /* Effects */
  --anim: all 0.25s;
}

@media (min-width: 768px) {
  :root {
    --gutter: calc(var(--spacer) / 2);
  }
}

@import url('styles.mobile.css');
@import url('styles.tablet.css');
