.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: var(--spacer-2);

  width: 100%;
  height: 100%;

  background-color: rgba(11, 18, 34, 0.9);
}

.modal-content {
  width: 100%;
  max-width: 760px;
  max-height: 80%;

  margin: 0 auto;
  padding: var(--spacer-2);

  border-radius: 10px;

  background-color: #fff;
  box-shadow: var(--card-box-shadow);

  overflow-y: auto;
}

.no-scroll {
  overflow: hidden;
}
