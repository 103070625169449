.signUpBanner-headline {
  margin-bottom: var(--spacer);
  color: var(--color10);
  font-size: 1.5rem;
  line-height: 1.3;
}

.signUpBanner .button {
  width: 100%;
  max-width: 220px;
}

.signUpBanner .button {
  --btn-height: 3rem;
}

.signUpBanner-actions {
  margin-top: var(--spacer);
  padding-left: 36px;
}
