.textinput-wrapper {
  &.has-toggle {
    position: relative;

    input {
      padding-right: 40px;
    }
  }

  .toggle {
    cursor: pointer;
    height: 38px;
    line-height: 38px;
    position: absolute;
    right: 1px;
    text-align: center;
    top: 1px;
    width: 40px;

    svg {
      --size: 20px;
    }
  }
}
