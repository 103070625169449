.publishBox {
  max-width: 620px;
}

.publishBox-header {
  margin-bottom: var(--spacer);
}

.cardList {
  display: flex;
  flex-flow: row wrap;
  gap: var(--gap-half);

  margin: 0;
  padding: 0;
}

.cardList-card {
  position: relative;
  border: none;
  background-color: var(--card-color-alt);
}

.cardList-card:hover {
  background-color: var(--card-color-alt);
}

@media (min-width: 960px) {
  .cardList-card {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .cardList-card .button {
    margin-top: auto;
  }
}
