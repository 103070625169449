/* !!! */
.addSourceForm {
  max-width: 660px;
}

.addSourceHeader {
  padding-bottom: var(--spacer);
  margin-bottom: calc(var(--spacer) * 1.5);
  border-bottom: var(--card-border);
}

.addSourceHeader > *:last-child {
  margin-bottom: 0;
}

.addSourceHeader-content {
  margin-top: var(--spacer-half);
}

#hubspot-add-source-form .legal-consent-container {
  margin-top: var(--form-spacer);
}

#hubspot-add-source-form .field {
  max-width: 60ch;
}

#hubspot-add-source-form .inputs-list {
  padding: 0;
}

#hubspot-add-source-form input[type='submit'] {
  max-width: 14ch;
  height: var(--btn-height);
  border: none;
  border-radius: var(--elem-radius);
  background-color: var(--color4);
  font-weight: var(--weight-medium);
  color: var(--color1);
  transition: var(--anim);
}

#hubspot-add-source-form input[type='submit']:hover {
  background-color: var(--color1);
  color: #fff;
}

#hubspot-add-source-form .hs-error-msgs {
  margin-top: 0.5rem;
  font-size: 0.8rem;
  font-style: italic;
  color: #fff;
}

#hubspot-add-source-form .actions {
  margin-top: var(--spacer);
}

.hs-form-booleancheckbox-display {
  display: flex;
  align-items: center;
}

.hs-form-booleancheckbox-display > span {
  margin-left: 1ch !important;
}

@media (min-width: 960px) {
  .addSourceHeader,
  .hubspotForm {
    padding: 0 var(--spacer-2) var(--spacer-half);
  }
}
