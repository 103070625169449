.app-overlay {
  --overlay-border-radius: calc(var(--elem-radius) * 3);

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(12, 19, 32, 0.83);
}

.app-overlay .col-wrapper {
  position: relative;
  flex: 1;

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--max-width);
  padding: 0;

  background-color: var(--color2);
}

@media (min-width: 620px) {
  .app-overlay {
    padding: var(--spacer);
  }

  .app-overlay .col-wrapper {
    box-shadow: 0 0 60px #0c1324; /* Darker version of var(--color0) */
    border-radius: var(--overlay-border-radius);
  }

  .app-overlay .cancelButton {
    position: absolute;
    top: -10px;
    right: -10px;
  }
}
