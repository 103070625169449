.maintenance-page {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;

  > div {
    text-align: center;

    img {
      display: block;
      height: 100px;
      margin: 0 auto;
    }

    p {
      display: block;
      padding-top: 20px;
    }
  }
}
