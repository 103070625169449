.alert {
  position: relative;
  margin-bottom: 0.35rem;
  padding: 0.1rem 1rem;

  font-size: 0.85rem;
  font-weight: var(--weight-medium);
  text-align: center;
  color: var(--color6);
}

.alert span {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding-right: 1ch;
  padding-left: 1ch;
  background-color: var(--bg-color);
}

.alert:before {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 0;
  display: block;
  width: 100%;
  height: 1px;
  background-color: var(--color4);
  content: '';
  opacity: 0.1;
}
