.addBanner {
  position: relative;
  width: 100%;
  padding: var(--spacer-half);

  border-radius: var(--elem-radius);
  background-color: var(--bg-color);
  background-image: linear-gradient(
    180deg,
    var(--color0) 0%,
    var(--color2) 100%
  );
}

.addBanner-wrap {
  display: flex;
  justify-content: space-evenly;
  gap: var(--spacer);
  max-width: calc(
    100% - 10px
  ); /* Alignment on mobile if there are scrollbars */
}

.addBanner .icon-button .icon {
  width: 1.4rem;
  height: 1.4rem;
}

.addBanner-header {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: auto;
}

.addBanner-headline {
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: var(--weight-medium);
  color: var(--color14);
}

.addBanner:hover {
  background-color: var(--color1);
  background-image: none;
}
