.video-embed {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.video-embed iframe,
.video-embed video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: var(--elem-radius);
}
