@media (max-width: 960px) {
  body.no-scroll .view {
    height: auto;
    overflow: visible;
  }

  .app-nav {
    position: fixed;
    left: 0;
    width: 100%;
    display: block;
    z-index: 1000;
    background-color: var(--color1);
  }

  .app-header {
    position: fixed;
    top: 0;
    z-index: 100;
  }

  .app-header .endpointForm,
  .app-header .publishButton,
  .app-header .logoutButton {
    display: none;
  }

  .app-studio {
    display: block;
    padding-top: 50px;
    height: calc(100vh - 50px);
    overflow: auto;
  }

  .app-control {
    padding-bottom: 60px;
  }

  .app-control > .col-wrapper {
    display: block;
    max-height: none;
  }

  .selectedHeader {
    gap: 8px;
  }

  .selectedHeader .toggle .icon {
    display: none;
  }

  .application-prompts.example-prompt {
    background-image: none;
    padding-left: var(--spacer-half);
  }

  .addBanner {
    display: none;
  }

  .schema-config-help {
    display: none;
  }

  .miniTutorial {
    flex-direction: column;
    justify-content: space-around;
    gap: var(--spacer);
    height: 30vh;
  }

  .miniTutorial .miniTutorial-step {
    width: 100%;
  }

  /* Begin GraphiQL customizations */

  .graphiql-wrapper {
    display: none;
  }

  .graphiql-wrapper .editorBar {
    display: block;
  }

  .graphiql-container .editorBar .resultWrap {
    height: 500px;
  }

  .graphiql-toolbar .run-container {
    flex: 0;
  }

  .graphiql-toolbar .button.copy-code {
    display: none;
  }

  .graphiql-container .run-dropdown {
    left: auto;
    max-width: calc(100vw - 20px);
    right: 0;
    width: calc(100vw - 20px);
  }

  .CodeMirror-vscrollbar,
  .CodeMirror-hscrollbar,
  .CodeMirror-scrollbar-filler,
  .CodeMirror-gutter-filler {
    display: none !important;
  }

  .view-run .app-studio,
  .view-results .app-studio {
    overflow-y: hidden;
  }

  .view-run .app-control,
  .view-results .app-control {
    display: none;
  }

  .view-run .graphiql-wrapper,
  .view-results .graphiql-wrapper {
    display: block;
    background: var(--color1);
  }

  .view-results .graphiql-wrapper {
    background-color: var(--color2);
  }

  .view-run .graphiql-container .editorBar .resultWrap {
    display: none;
  }

  .graphiql-container .CodeMirror,
  .graphiql-wrapper .graphiql-schema-view {
    position: static;
  }

  .view-run .graphiql-wrapper .graphiql-schema-view,
  .view-run .graphiql-wrapper .graphiql-schema-view {
    width: 100%;
    height: auto;
  }

  /* Show the exploration experience, first panel */
  .view-run .graphiql-toolbar > .column:nth-child(2) {
    display: none;
  }

  .view-run .CodeMirror-scroll {
    height: calc(100vh - 186px);
  }

  /* Show the results experience, second panel */
  .view-results .graphiql-toolbar,
  .view-results .graphiql-container .editorBar .queryWrap,
  .view-results .graphiql-schema-view {
    display: none;
  }

  .view-results .CodeMirror-scroll {
    height: calc(100vh - 126px);
  }

  /* Publish overrides */
  .app-focal {
    padding-top: 40px;
  }

  .cardList-card {
    width: 100%;
  }
}
