.app-nav {
  display: none;
}

.app-nav ul {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 1px;
}

.app-nav li {
  position: relative;
  flex: 1;
}

.app-nav .button {
  max-width: none;
  width: 100%;
}

.app-nav .button > span {
  --btn-height: 50px;
  border-radius: 0;
}

.app-nav .schema-count {
  position: absolute;
  left: 50%;
  bottom: -3px;
  z-index: 1;

  pointer-events: none;
  transform: translateX(-50%);
}

.app-nav .badge {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;

  border-radius: 50%;
  border: 2px solid var(--color3);
  background-color: var(--bg-color);

  font-size: 0.7rem;
  font-weight: var(--weight-bold);

  color: var(--font-color);
}

.app-nav .status .icon {
  width: 20px;
  height: 20px;
  color: var(--color10);
}

@media (min-width: 960px) {
  .app-nav {
    padding-top: var(--spacer-half);
  }

  .app-nav ul {
    flex-direction: column;
  }

  .app-nav a {
    height: 68px;
  }

  .app-nav .status {
    position: absolute;
    top: 0;
    right: -8px;
    bottom: auto;
    left: auto;
    z-index: 1;

    width: 20px;
    height: 100%;
  }

  .app-nav .badge {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
}
