.split-list {
  padding: 0 0 0 1.35rem;
  column-count: 2;
  column-gap: var(--gap);
}

.split-list li {
  margin-bottom: 0.75rem;
  line-height: 1.35;
  list-style: disc;
}

.highlights-list ul {
  padding: 0;
}

.highlights-list li {
  position: relative;
  padding-left: 36px;
  list-style: none;
  line-height: 1.4;
  font-size: 0.85rem;
}

.highlights-list li:before {
  position: absolute;
  top: 4px;
  left: 0;
  display: block;
  width: 24px;
  height: 24px;
  background-image: url(../../images/checkbox.svg);
  background-repeat: no-repeat;
  background-size: cover;
  content: '';
}

.highlights-list li + li {
  margin-top: calc(var(--spacer) * 1.15);
}

.highlights-list strong {
  display: block;
  margin-bottom: 0.35rem;
  font-size: 1rem;
  line-height: 1.2;
  color: var(--font-color);
}

.object-list {
  display: flex;
  flex-direction: column;
  gap: var(--spacer-half);
  margin: 0 0 var(--spacer-half);
  padding: 0;
}

.object-list > li {
  list-style: none;
}
