.application-prompts.example-prompt {
  background: var(--card-color-alt);
  border-radius: var(--elem-radius);
  background-image: url(../../images/graphics/hand.svg);
  background-position: top left -34px;
  background-repeat: no-repeat;
  background-size: auto 65%;
  padding: var(--spacer) var(--spacer-half);
  padding-left: 6.15rem;
}

.application-prompts .prompt-headline {
  color: #fff;
}

.application-prompts .button-group {
  margin-top: var(--spacer);
}
