.CodeMirror-info {
  background-color: var(--color0);
  border-radius: var(--elem-radius);
  box-shadow: var(--box-shadow);
  color: #eee;
  font-family: var(--font-body);
  font-size: 16px;
  line-height: 1.3;
  max-width: 520px;
  padding: 20px;
}

.CodeMirror-info .type-name {
  color: var(--color10);
}

.CodeMirror-info .field-name {
  color: #c0c0c0;
}
