.icon {
  --size: 1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  width: var(--size);
  height: var(--size);
}

/* Special instance where we have a wrapper */

.icon.xsmall {
  --size: 1.2rem;
}

.icon.small {
  --size: 1.8rem;
}

.icon.medium {
  --size: 2.6rem;
}

.icon.large {
  --size: 5rem;
}

.icon.xlarge {
  --size: 8rem;
}

.icon.highlight {
  border-radius: 50%;
  background: #f2f2f2;
}

.icon.highlight img,
.icon.highlight svg {
  max-width: 76%;
}
