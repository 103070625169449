.dialog {
  /*
  position: absolute;
  right: var(--spacer);
  bottom: calc(var(--spacer) * 1.5);
  z-index: 100;
  */
  padding: var(--spacer);
  max-width: 450px;
  width: 92%;
  box-shadow: var(--box-shadow);
  border-radius: var(--elem-radius);
  background-color: var(--color0);

  background-repeat: no-repeat;

  font-size: 0.9rem;
}

.dialog a {
  color: var(--color12);
}

.dialog a:hover {
  text-decoration: none;
}

.dialog-actions {
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacer-half);
  margin-top: var(--spacer);
}

.dialog.schema {
  background-image: url(../../images/graphics/cubes.svg);
  background-position: right -10px bottom -10px;
  background-size: auto 90%;
}

.dialog.rocket {
  background-image: url(../../images/graphics/rocket.svg);
  background-position: right -10px bottom -10px;
  background-size: auto 80%;
}

/* !!! */

.cookie-banner {
  display: flex;
  align-items: center;
  gap: var(--spacer);
  padding: var(--spacer-half);
  max-width: 640px;
}

.cookie-banner p {
  margin-bottom: 0;
}

.cookie-banner .dialog-actions {
  margin-top: 0;
}
