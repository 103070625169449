@media (min-width: 769px) and (max-width: 1024px) {
  body.no-scroll .view {
    height: auto;
    overflow: visible;
  }

  .graphiql-wrapper {
    background-color: var(--color1);
    padding: 0;
  }

  .graphiql-toolbar .button.tab,
  .graphiql-toolbar .button.copy-code {
    display: none;
  }

  .graphiql-toolbar .run-container {
    flex: 0;
  }

  .graphiql-container .editorBar {
    display: block;
  }

  .graphiql-container .editorBar .queryWrap {
    border-bottom: 4px solid var(--color2);
    height: 50%;
    left: 0;
    padding: 20px 20px 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  .graphiql-container .editorBar .resultWrap {
    border-top: 4px solid var(--color2);
    bottom: 0;
    height: 50%;
    left: 0;
    padding: 20px 20px 0;
    position: absolute;
    right: 0;
  }

  .graphiql-container .docExplorerWrap {
    display: none;
  }

  .graphiql-container .topBarWrap {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .graphiql-container .graphiql-toolbar {
    display: block;
  }

  .graphiql-container .graphiql-toolbar .tabs {
    border: none;
    display: block;
    margin: 0;
  }

  #root,
  #root .view {
    height: 100vh;
  }
}
