.icon-button {
  display: inline-block;
  max-width: var(--btn-max-width);
  padding: 0;
  border: none;
  background: none;
  text-decoration: none;
  cursor: pointer;
}

.icon-button > span {
  display: inline-flex;
  align-items: center;
  height: 100%;
}
