.app-actions .col-wrapper {
  padding: var(--gutter);
  background-color: var(--color1);
}

.app-actions form {
  margin-top: var(--spacer);
}

.run-button {
  text-transform: uppercase;
}

.run-button > span {
  height: calc(var(--btn-height) * 4.5 / 5);
  font-size: 0.8rem;
}

.run-button .icon {
  width: 0.9rem;
  height: 0.9rem;
}

.run-button svg.moreIcon {
  margin: 0 0 0 0.5rem;
}

.graphiql-container .run-dropdown {
  background-color: var(--bg-color);
  border-radius: var(--elem-radius);
  max-height: calc(100vh - 150px);
  max-width: 240px;
  min-width: 180px;
  overflow: auto;
  position: absolute;
  right: 0;
  top: calc(100% + 8px);
}

.graphiql-container .run-dropdown ul {
  flex-direction: column;
  gap: 0;

  margin: 0;
  padding: 0;

  border-radius: var(--elem-radius);
  border: 4px solid var(--color2);
  background-color: var(--color2);

  text-align: left;
}

.graphiql-container .run-dropdown button {
  appearance: none;
  -webkit-appearance: none;

  width: 100%;
  padding: 0.5rem 0.8rem;

  border: none;
  border-radius: var(--elem-radius);
  background-color: var(--color2);

  font-family: var(--font-body);
  font-size: 0.9rem;
  text-align: left;
  color: #fff;

  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
}

.graphiql-container .run-dropdown button:hover {
  background-color: var(--color1);
  transition: var(--anim);
}

.graphiql-container .run-dropdown button[disabled] {
  background-color: var(--color2);
  cursor: default;
  transition: none;
}
