.app-main {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: var(--header-height);
}

.app-studio {
  width: 100%;
}

.app-control .scrollable {
  padding-bottom: var(--spacer-half);
  padding-right: calc(var(--gutter) / 2);
}

.col-wrapper {
  flex: 1;
  border-radius: var(--elem-radius) var(--elem-radius) 0 0;
}

.app-notifications {
  position: absolute;
  right: var(--spacer);
  bottom: calc(var(--spacer) * 1.5);
  z-index: 100;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--spacer-half);
}

@media (min-width: 960px) {
  .app-main .app-studio {
    flex: 1;
    display: grid;
    grid-template-columns: 480px minmax(0, 1fr);
  }
}
