.miniTutorial {
  display: flex;
  gap: calc(var(--gutter) * 2);
  margin: 0;
  padding: 0;
  height: 40%;
  min-height: 300px;
}

.miniTutorial li {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;

  width: 50%;

  font-size: 1.3rem;
  text-align: center;
  opacity: 0.3;
}
