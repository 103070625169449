.schema-card {
  --media-size: 8px;
  display: flex;
  padding: 0;
  gap: var(--spacer-half);
  border-left: none;
}

.schema-card.selected {
  background-color: var(--color2);
}

.schema-card .card-title {
  font-weight: var(--weight-medium);
}

.schema-card .card-media {
  flex: 0 1 var(--media-size);
  display: flex;
  justify-content: center;
  align-items: center;
}

.schema-card-icon {
  width: var(--media-size);
  height: 100%;
  border-radius: var(--elem-radius) 0 0 var(--elem-radius);
}

.schema-card .card-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 0;
}

.schema-card .card-header > *:last-child {
  margin-bottom: 0;
}

.schema-card .card-action-button {
  margin-left: 0 !important; /* !!! */
  padding: 0 var(--spacer-half);
}

.schema-card .card-action-button .icon {
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  background-color: var(--color2);
}

.schema-card:hover .card-action-button .icon {
  background-color: var(--card-color);
  transition: var(--anim);
}

.schema-card .config-status {
  apperance: none;
  border: none;
  background: none;

  font-size: 0.85rem;
  font-weight: var(--weight-medium);
  cursor: pointer;
}

.schema-card .configured {
  color: var(--color4);
}

.schema-card .needs-config {
  color: var(--color10);
}

.schema-card .config-status > span {
  display: inline-flex;
  align-items: center;
  gap: 0.8ch;
}

.schema-card .config-status .icon {
  width: 1rem;
  height: 1rem;
}

.schema-card .card-header > *:last-child {
  margin-bottom: 0;
}
