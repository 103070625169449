.card {
  position: relative;
  padding: var(--spacer-half);
  border: var(--card-border);
  border-radius: var(--elem-radius);
}

.card.ghost {
  background-color: transparent;
}

.card p {
  font-size: 0.85rem;
}

.card:hover {
  background-color: var(--card-color);
  transition: var(--anim);
}
