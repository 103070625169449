body {
  background-color: var(--bg-color);
  font-family: var(--font-body);
  font-size: 15px;
  line-height: 1.35;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.3;
}

h1,
.h1 {
  font-size: 1.3rem;
}

h2,
.h2 {
  font-size: 1.15rem;
}

h3,
.h3,
h4,
.h4 {
  font-size: 1rem;
}

h1,
h2 {
  font-weight: var(--weight-bold);
}

h1,
h2,
h3 {
  margin-bottom: 0.5rem;
}

p {
  margin-bottom: 1rem;
  line-height: 1.45rem;
  color: var(--font-color-alt);
}

.lead {
  font-size: 1.1rem;
  line-height: 1.4;
}

.subheadline {
  display: block;
  margin-bottom: 0.8rem;
  font-weight: var(--weight-medium);
  color: var(--color7);
}

.eyebrow {
  display: block;
  font-size: 1.1rem;
  font-weight: var(--weight-bold);
  opacity: 0.6;
}

.weight-normal {
  font-weight: var(--weight-normal);
}

.weight-medium {
  font-weight: var(--weight-medium);
}

.weight-bold {
  font-weight: var(--weight-bold);
}

.weight-black {
  font-weight: var(--weight-black);
}
