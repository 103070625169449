/* 
  !!!
  TODO: Specificity overrides. Abstract all card styles from schema and combo card
*/
.combo-card.combo-card .card-media {
  display: none;
}

.combo-card.combo-card {
  gap: calc(var(--spacer-half) / 2);
  padding-top: 12px;
  padding-bottom: 12px;
  border-left: var(--card-border);
}

.combo-card.combo-card .card-header {
  padding: 0 0 0 12px;
}

.combo-card.has-meta {
  padding-bottom: 0;
}

.combo-card.has-meta .card-header {
  padding: 0 0 2px 12px;
}

.combo-card {
  flex-flow: row wrap;

  background-image: url(../../images/graphics/combo-faded.svg);
  background-position: right -20px center;
  background-repeat: no-repeat;
  background-size: 140px auto;
}

.combo-card .card-title {
  margin-bottom: 0;
}

.combo-card-count {
  position: relative;
  z-index: 0;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  margin-right: -4px;

  width: 1.4rem;
  height: 1.4rem;

  border-radius: 50%;
  background-color: var(--color4);

  font-size: 0.85rem;
  font-weight: var(--weight-bold);
  color: var(--color1);
}

.combo-card-count + .icon {
  position: relative;
  z-index: 1;
}

.combo-card:hover .combo-card-count {
  color: var(--card-color);
  transition: var(--anim);
}

.combo-card-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 4px 14px 4px 12px;
  background-color: var(--color3);
  font-size: 0.8rem;
}

.combo-card-meta .combo-card-infoButton {
  --btn-height: 24px;
  --btn-primary-color: var(--font-color);
  --btn-bg: transparent;
  --btn-color: var(--btn-primary-color);
  margin-left: auto;
}

.combo-card-meta .combo-card-infoButton > span {
  min-width: 0;
  padding-left: 0.4em;
  padding-right: 0.4em;
}

.combo-card .communityBadge {
  align-self: center;
}
