input:not([type='checkbox']),
input:not([type='radio']),
select,
textarea {
  appearance: none;
  -webkit-appearance: none;
  height: var(--input-height);
  width: 100%;
  padding: 0.35em 0.5em;
  border-radius: 2px;
  border: var(--input-border);
  background-color: transparent;
  color: #fff;
}

input[type='search'] {
  padding-left: calc(30px + 0.5em);
  /*background-image: url(/images/icons/mag-glass.svg);*/
  background-position: left 0.5em center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

input[type='checkbox'] {
  appearance: checkbox;
  -webkit-appearance: checkbox;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

textarea {
  min-height: 4rem;
}

label {
  display: block;
  margin-bottom: 0.3rem;
  font-weight: var(--weight-medium);
}

fieldset {
  border: none;
}

.field.checkbox,
.field.toggle {
  display: flex;
  align-items: center;
}

.field.checkbox input {
  margin-right: 1ch;
  order: 0;
}

.field.checkbox label,
.field.toggle label {
  order: 1;
  margin-bottom: 0;
}

.field.toggle label {
  display: inline-block;
  position: relative;
}

.field.toggle label:before {
  position: relative;
  display: inline-block;
  vertical-align: middle;

  margin-right: 1ch;
  height: 24px; /* !!! */
  width: 38px;

  border: 2px solid var(--color5);
  border-radius: 30px;

  content: '';
  cursor: pointer;
}

.field.toggle label:after {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: var(--color5);
  content: '';
  cursor: pointer;

  transform: translateY(5px) translateX(5px);
  transition: var(--anim);
}

.field.toggle input[type='checkbox']:checked ~ label:before {
  background-color: var(--color5);
  transition: var(--anim);
}

.field.toggle input[type='checkbox']:checked ~ label:after {
  background-color: #fff;
  transform: translateY(5px) translateX(19px);
}

.field-group + .field,
.field + .field {
  margin-top: var(--form-spacer);
}

.field-group,
.register-group {
  display: flex;
  flex-wrap: wrap;
}

.field-group .field {
  flex: 1;
}

.field-group .field + .field {
  margin-top: 0;
  margin-left: var(--form-spacer);
}

.form-header {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: var(--spacer);
}

.form-header-instruction {
  flex: 1;
}

.form-header h2 {
  margin-bottom: 0;
}

.form-header p {
  margin-bottom: 0;
  font-size: 0.85rem;
}

.form-header .button {
  margin-left: auto;
}

.inline-label {
  display: flex;
  border: var(--input-border);
  border-radius: var(--elem-radius);
}

.inline-label label {
  flex: 1;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  border-radius: var(--elem-radius) 0 0 var(--elem-radius);
  border-right: var(--input-border);
  font-size: 0.8rem;
  text-transform: uppercase;
  white-space: nowrap;
  color: #fff;
}

.inline-label input {
  border: none;
}
