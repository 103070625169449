.config-card {
  padding: 0;
  border: none;
  border-radius: var(--elem-radius);
  background-color: transparent;
}

.config-card:hover {
  background-color: transparent;
}

.config-card-header {
  margin-bottom: var(--spacer-half);
}

.schema-config .form-header,
.schema-config-help,
.schema-config .object-list {
  padding: var(--spacer) var(--spacer-half);
}

.schema-config .form-header {
  margin-bottom: 0;
  padding-bottom: calc(var(--spacer) * 0.75);
  border-bottom: 1px solid #1d4e8980;
}

.schema-config .object-list {
  gap: var(--spacer);
}

.schema-config-help {
  margin-top: auto;
  background-color: var(--color1);
  border: none;
}

@media (min-width: 620px) {
  .schema-config .col-wrapper {
    flex: 0 1 auto;
    max-width: calc(var(--max-width) * 1 / 2.5);
  }

  .schema-config .form-header,
  .schema-config-help,
  .schema-config .object-list {
    padding: var(--spacer) 40px;
  }

  .schema-config-help {
    margin-top: var(--spacer-half);
    border-radius: 0 0 var(--overlay-border-radius) var(--overlay-border-radius);
  }
}
