.app-focal {
  width: 100%;
  height: 100%;
}

.app-focal-overview,
.app-focal-aside {
  padding: var(--spacer);
}

.app-focal-overview {
  display: flex;
  justify-content: center;
}

.app-focal-overview.empty {
  align-items: center;
}

.app-focal-empty {
  display: flex;
  gap: var(--spacer-half);
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 380px;
  background-color: var(--color2);
  text-align: center;
}

.app-focal-aside {
  padding: var(--spacer);
  background-color: var(--color1);
}

.app-focal h1 {
  color: #fff;
}

.app-focal .copy-field {
  max-width: none;
}

@media (min-width: 960px) {
  .app-focal {
    display: grid;
    grid-template-columns: 1fr 460px;
  }

  .app-focal-overview,
  .app-focal-aside {
    padding-top: var(--spacer);
  }
}
